import Router from 'next/router';
import { call, fork, put, select, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { HYDRATE } from 'next-redux-wrapper';
import { AdminEventsApi, AdminOrganizationsApi } from 'api';
import {
  DefaultLanguageEventDisplay,
  EventCategory,
  EventImageDisplay,
  EventTargetAge,
  EventUpdate,
  EventVideoDisplay,
  Market,
  Tag,
} from 'api/admin/models';

import { isServer } from 'utils/next';
import { routes } from 'utils/routing';
import { GetAction } from 'store/types';
import { AdminEventImagesActions, AdminEventVideosActions } from 'store/actions';
import {
  AdminAddonsSelectors,
  AdminEventImagesSelectors,
  AdminEventVideosSelectors,
  AdminTicketsSelectors,
  EventCategoriesSelectors,
  EventTargetAgesSelectors,
  MarketsSelectors,
  TagsSelectors,
} from 'store/selectors';

import { createFakeRequest } from 'store/entities/sagasHelpers';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import { sagasHandlersFactory as featureSagasHandlersFactory } from 'store/features/utils';
import { MEDIA_ORDER_OFFSET, MEDIA_ORDER_SPREAD } from './constants';
import { createChangeOrderHandler } from './sagasUtils';
import * as actions from './actions';
import * as selectors from './selectors';
import { createLoadUnsavedIds, createSyncUpdateUnsaved, getUnsaved, setUnsaved } from './unsavedUtils';
import { updateUnsavedAdminTicket } from '../adminTickets/sagas';
import { updateUnsavedAdminEventSettings } from '../adminEventSettings/sagas';
import { updateUnsavedAdminAddon } from '../adminAddons/sagas';

const UNSAVED_TYPE = 'event';

const eventDisplayToEventUpdate = (diff: Partial<DefaultLanguageEventDisplay>): Partial<EventUpdate> => ({
  ...(diff as any),
  ...(diff.categories && {
    categories: diff.categories?.map(({ id }) => id) || [],
  }),
  ...(diff.target_ages && {
    target_ages: diff.target_ages?.map(({ id }) => id) || [],
  }),
  ...(diff.types && {
    types: diff.types?.map(({ id }) => id) || [],
  }),
  ...(diff.whats_included_tags && {
    whats_included_tags: diff.whats_included_tags?.map(({ id }) => id) || [],
  }),
  ...(diff.market && {
    market: diff.market.id,
  }),
  ...(diff.has_location && {
    address: diff.address,
  }),
});

function* eventUpdateToEventDisplay(event: EventUpdate) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { start_at, end_at, default_currency, questions, ...used } = event;
  const { categories, types, target_ages, whats_included_tags, timezone, virtual_platform, market, ...rest } = used;

  const whatIncludedTags: Tag[] = yield select(TagsSelectors.whatsIncludedTags);
  const targetAges: EventTargetAge[] = yield select(EventTargetAgesSelectors.eventTargetAges);
  const eventTypes: EventCategory[] = yield select(EventCategoriesSelectors.getEventTypes);
  const topics: EventCategory[] = yield select(EventCategoriesSelectors.getEventTopics);
  const markets: Market[] = yield select(MarketsSelectors.markets);

  return {
    ...rest,
    ...(timezone && ({ timezone } as any)),
    ...(virtual_platform && ({ virtual_platform } as any)),
    ...(types &&
      types.length !== 0 && {
        types: types.map((typeId) => eventTypes.find(({ id }) => id === typeId)),
      }),
    ...(categories &&
      categories.length !== 0 && {
        categories: categories.map((categoryId) => topics.find(({ id }) => id === categoryId)),
      }),
    ...(target_ages && {
      target_ages: targetAges.filter(({ id }) => target_ages.includes(id)),
    }),
    ...(whats_included_tags && {
      whats_included_tags: whatIncludedTags.filter(({ id }) => whats_included_tags.includes(id)),
    }),
    ...(market && {
      market: markets.find(({ id }) => market === id),
    }),
  };
}

const loadUnsavedIds = createLoadUnsavedIds({
  type: UNSAVED_TYPE,
  action: actions.addUnsavedAdminEvent,
});

const updateUnsavedAdminEvent = createSyncUpdateUnsaved({
  type: UNSAVED_TYPE,
  actions: actions.updateAdminEvent,
  entityBuilder: eventDisplayToEventUpdate,
  originalRequest: AdminOrganizationsApi.getEvent,
  requestArgsBuilder: (payload) => {
    const { id, params } = payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

function* handlePublishUnsaved(action: GetAction<typeof actions.publishUnsaved.request>): any {
  const eventId = action.payload.id;
  const { organizationId } = action.payload.params;
  const adminEventTicketsIds = yield select(AdminTicketsSelectors.adminEventTicketsIds, { eventId });
  const adminEventAddonsIds = yield select(AdminAddonsSelectors.adminEventAddonsIds, { eventId });
  try {
    yield call(updateUnsavedAdminEvent, {
      id: eventId,
      params: { organizationId },
    });

    yield call(updateUnsavedAdminEventSettings, {
      id: eventId,
    });

    for (let i = 0; i < adminEventTicketsIds.length; i += 1) {
      yield call(updateUnsavedAdminTicket, {
        id: adminEventTicketsIds[i],
        params: { eventId },
      });
    }

    for (let i = 0; i < adminEventAddonsIds.length; i += 1) {
      yield call(updateUnsavedAdminAddon, {
        id: adminEventAddonsIds[i],
        params: { eventId },
      });
    }

    yield put(
      actions.publishUnsaved.success({
        result: {
          published: true,
          isPublished: true,
        },
        ...action.payload,
      }),
    );
  } catch (e) {
    if (e instanceof Error)
      yield put(
        actions.publishUnsaved.failure({
          error: e,
          notifyError: false,
          ...action.payload,
        }),
      );
  }
}

const handleGetAdminEventsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminEvents,
  request: AdminOrganizationsApi.getEvents,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { organization, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminEventsState, params);
    return [organization, { params: { ...rest, page, page_size } }];
  },
});

const handleGetAdminOrgEventsAutocompleteRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrgEventsAutocomplete,
  request: AdminOrganizationsApi.getOrgEventsAutocomplete,
  requestArgsBuilder: (action) => {
    const { params } = action.payload;
    const { organizationId, q } = params;
    return [organizationId, { params: { q } }];
  },
  transformResponse: (response) => ({ results: response }),
});

const handleGetAdminAllEventsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminAllEvents,
  request: AdminEventsApi.getAdminAllEvents,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { organization, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminAllEventsState, params);
    return [organization, { params: { ...rest, page, page_size } }];
  },
});

const handleGetAdminAllCCEventsRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminAllCCEvents,
  request: AdminEventsApi.getAdminAllCCEvents,
  notifyError: false,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { organization, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminAllCCEventsState, params);
    return [organization, { params: { organization, ...rest, page, page_size } }];
  },
});

const handleGetAdminEventRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminEvent,
  request: AdminOrganizationsApi.getEvent,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
  transformResponse: function* transformResponse(response, action) {
    const unsavedIds: number[] = yield select(selectors.getUnsavedIds);
    if (unsavedIds.includes(action.payload.id)) {
      const data = getUnsaved(UNSAVED_TYPE, action.payload.id);
      if (data) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return { ...data, questions: response?.questions || data?.questions || [] };
      }
    }

    return response;
  },
});

const handleUpdateAdminEventRequest = sagasHandlersFactory.createUpdateOneRequestHandler({
  actions: actions.updateAdminEvent,
  request: AdminOrganizationsApi.partialUpdateEvent,
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { organizationId } = params;
    return [organizationId, id, { body: entity }];
  },
});

const handleRuntimeUpdateAdminEvent = sagasHandlersFactory.createRuntimeUpdateHandler({
  actions: actions.runtimeUpdateAdminEvent,
  request: createFakeRequest(AdminOrganizationsApi.partialUpdateEvent)({
    shouldCallFakeRequest: function* shouldCallFakeRequest(organizationId, eventId): any {
      const isPublished: any = yield select(selectors.isPublished, Number(eventId));
      const hasIgnoredUnsavingId: boolean = yield select(selectors.hasIgnoredUnsavingId, Number(eventId));
      return isPublished && !hasIgnoredUnsavingId;
    },
    fakeRequest: function* fakeRequest(organizationId, eventId, options): any {
      const id = Number(eventId);
      yield put(actions.addUnsavedAdminEvent(id));
      const adminEvent: any = yield select(selectors.adminEventById, id);
      const updates: any = yield call(eventUpdateToEventDisplay, options.body);
      const unsaved = {
        ...adminEvent,
        ...updates,
        ...(updates.is_virtual !== undefined && !updates.is_virtual && { virtual_platform: '', livestream_link: '' }),
      };
      yield call(setUnsaved, UNSAVED_TYPE, id, unsaved);

      return unsaved;
    },
  }),
  requestArgsBuilder: (action) => {
    const { id, params, entity } = action.payload;
    const { organizationId } = params;
    return [organizationId, id, { body: entity }];
  },
});

const handleDeleteAdminEventRequest = sagasHandlersFactory.createDeleteOneRequestHandler({
  actions: actions.deleteAdminEvent,
  request: AdminOrganizationsApi.deleteEvent,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

const handlePublishAdminEventRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.publishAdminEvent,
  request: AdminOrganizationsApi.publishEvent,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId, ...rest } = params;
    return [organizationId, id, { body: rest }];
  },
  transformResponse: (response, action) => ({ published: action.payload.params.confirm_publish }),
  notifyError: (error, action) => action.payload.params.confirm_publish,
});

const handleSaveAsDraftAdminEventRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.saveAsDraftAdminEvent,
  request: AdminOrganizationsApi.saveEventAsDraft,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

const handleCancelAdminEventRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.cancelAdminEvent,
  request: AdminOrganizationsApi.cancelEvent,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

const handleArchiveAdminEventRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.archiveAdminEvent,
  request: AdminOrganizationsApi.archiveEvent,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

const handleUnarchiveAdminEventRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.unarchiveAdminEvent,
  request: AdminOrganizationsApi.unarchiveEvent,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

const handleMarkAsTestAdminEventRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.markAsTestAdminEvent,
  request: AdminOrganizationsApi.markAsTestEvent,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

const handleUpdateAdminCCEventStatusRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.updateAdminCCEventStatus,
  request: AdminOrganizationsApi.updateEventCCStatus,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId, ...rest } = params;
    return [organizationId, id, { body: rest }];
  },
});

const handleCloneAdminEventRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.cloneAdminEvent,
  request: AdminOrganizationsApi.cloneAdminEvent,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { organizationId } = params;
    return [organizationId, id];
  },
});

function* handleCloneAdminEventSuccess(action: GetAction<typeof actions.cloneAdminEvent.success>) {
  const { params, result } = action.payload;
  const { organizationId } = params;
  const { id } = result;
  const route = routes.adminEditEvent(organizationId, id);
  yield call(Router.push, route.href);
}

function* handleSaveAsDraftAdminEventSuccess(action: GetAction<typeof actions.saveAsDraftAdminEvent.success>) {
  const { id, params } = action.payload;
  const { organizationId } = params;
  const route = routes.adminEditEvent(organizationId, id);
  yield call(Router.push, route.href);
}

function* handleChangeAdminEventShowQuestions(
  action: GetAction<
    | typeof actions.changeAdminEventShowBuyerOnlyQuestions
    | typeof actions.changeAdminEventShowAllAttendeesQuestions
    | typeof actions.changeAdminEventShowTicketsQuestions
    | typeof actions.changeAdminEventShowAddonsQuestions
  >,
) {
  const { organizationId, eventId, ...rest } = action.payload;
  yield put(
    actions.updateAdminEvent.request({
      id: eventId,
      params: { organizationId },
      entity: rest,
    }),
  );
}

export function* getMedias(params: { eventId: number }): any {
  const images: any = yield select(AdminEventImagesSelectors.adminEventGalleryImage, params);
  const videos: any = yield select(AdminEventVideosSelectors.adminEventVideos, params);
  return images.concat(videos) as (EventImageDisplay | EventVideoDisplay)[];
}

export function* getNextMediaOrder(params: { eventId: number }): any {
  const medias: any = yield call(getMedias, params);
  return (medias.length === 0 ? MEDIA_ORDER_OFFSET : medias[medias.length - 1].order) + MEDIA_ORDER_SPREAD;
}

const handleChangeAdminEventMediaOrder = createChangeOrderHandler<
  GetAction<typeof actions.changeAdminEventMediaOrder>,
  EventImageDisplay | EventVideoDisplay
>({
  spread: MEDIA_ORDER_SPREAD,
  offset: MEDIA_ORDER_OFFSET,
  getEntities: getMedias,
  updateEntityOrder: function* update(media, order, params) {
    if ((media as EventVideoDisplay).link) {
      yield put(
        AdminEventVideosActions.updateAdminEventVideo.request({
          id: media.id,
          params,
          entity: {
            order,
            link: (media as EventVideoDisplay).link,
          },
        }),
      );
    } else {
      yield put(
        AdminEventImagesActions.updateAdminEventImage.request({
          id: media.id,
          params,
          entity: {
            order,
            image: (media as EventImageDisplay).image.id,
          },
        }),
      );
    }
  },
});

export default function* adminEventsSagas() {
  yield takeLeading(actions.getAdminEvents.request.type, handleGetAdminEventsRequest);
  yield takeLeading(actions.getAdminOrgEventsAutocomplete.request.type, handleGetAdminOrgEventsAutocompleteRequest);
  yield takeLeading(actions.getAdminAllEvents.request.type, handleGetAdminAllEventsRequest);
  yield takeLeading(actions.getAdminAllCCEvents.request.type, handleGetAdminAllCCEventsRequest);
  yield takeLeading(actions.getAdminEvent.request.type, handleGetAdminEventRequest);
  yield takeEvery(actions.updateAdminEvent.request.type, handleUpdateAdminEventRequest);
  yield takeEvery(actions.deleteAdminEvent.request.type, handleDeleteAdminEventRequest);
  yield takeEvery(actions.publishAdminEvent.request.type, handlePublishAdminEventRequest);
  yield takeEvery(actions.publishUnsaved.request.type, handlePublishUnsaved);
  yield takeEvery(actions.saveAsDraftAdminEvent.request.type, handleSaveAsDraftAdminEventRequest);
  yield takeEvery(actions.cloneAdminEvent.request.type, handleCloneAdminEventRequest);
  yield takeEvery(actions.cancelAdminEvent.request.type, handleCancelAdminEventRequest);
  yield takeEvery(actions.archiveAdminEvent.request.type, handleArchiveAdminEventRequest);
  yield takeEvery(actions.unarchiveAdminEvent.request.type, handleUnarchiveAdminEventRequest);
  yield takeEvery(actions.markAsTestAdminEvent.request.type, handleMarkAsTestAdminEventRequest);
  yield takeEvery(actions.updateAdminCCEventStatus.request.type, handleUpdateAdminCCEventStatusRequest);
  yield takeEvery(actions.saveAsDraftAdminEvent.success.type, handleSaveAsDraftAdminEventSuccess);
  yield takeEvery(actions.cloneAdminEvent.success.type, handleCloneAdminEventSuccess);
  yield takeEvery(actions.changeAdminEventShowBuyerOnlyQuestions.type, handleChangeAdminEventShowQuestions);
  yield takeEvery(actions.changeAdminEventShowAllAttendeesQuestions.type, handleChangeAdminEventShowQuestions);
  yield takeEvery(actions.changeAdminEventShowTicketsQuestions.type, handleChangeAdminEventShowQuestions);
  yield takeEvery(actions.changeAdminEventShowAddonsQuestions.type, handleChangeAdminEventShowQuestions);
  yield takeEvery(actions.changeAdminEventMediaOrder.type, handleChangeAdminEventMediaOrder);
  yield fork(handleRuntimeUpdateAdminEvent);

  if (!isServer()) {
    yield fork(function* wait() {
      yield take(HYDRATE);
      yield call(loadUnsavedIds);
    });
  }
}
