import { delay, put, call, race, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { AdminEventsApi } from 'api';
import { GetAction } from 'store/types';
import { getNextPageParams, sagasHandlersFactory } from 'store/entities/utils';
import { sagasHandlersFactory as featureSagasHandlersFactory } from 'store/features/utils';
import * as actions from './actions';
import * as selectors from './selectors';

const handleGetAdminOrderRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrder,
  request: AdminEventsApi.getAdminOrder,
  requestArgsBuilder: (action) => [action.payload.params.eventId, action.payload.id],
});

const handleGetAdminOrdersRequest = sagasHandlersFactory.createGetManyRequestHandler({
  actions: actions.getAdminOrders,
  request: AdminEventsApi.getAdminOrders,
  requestArgsBuilder: function* builder(action) {
    const { params } = action.payload;
    const { eventId, status, page_size, ...rest } = params;
    const { page } = yield call(getNextPageParams, page_size, selectors.adminOrdersState, params);

    return [eventId, { params: { ...rest, status: status === 'all' ? undefined : status, page, page_size } }];
  },
});

const handleGetAdminOrdersStatsRequest = sagasHandlersFactory.createGetOneRequestHandler({
  actions: actions.getAdminOrdersStats,
  request: AdminEventsApi.getAdminOrdersStats,
  requestArgsBuilder: (action) => action.payload.id,
  transformResponse: (response, action) => ({
    id: action.payload.id,
    counters: response,
  }),
});

const handleFullRefundAdminOrderRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.fullRefundAdminOrder,
  request: AdminEventsApi.fullRefundAdminOrder,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId, data } = params;
    return [eventId, id, { body: data }];
  },
});

function* handleFullRefundAdminOrderSuccess(action: GetAction<typeof actions.fullRefundAdminOrder.success>): any {
  const { id, params } = action.payload;
  const { eventId } = params;

  for (let i = 0; i < 10; i += 1) {
    yield delay(i ** 2 * 1000 + 5000);
    yield put(actions.getAdminOrder.request({ id, silent: true, params: { eventId } }));

    while (true) {
      const effects = yield race([take(actions.getAdminOrder.success.type), take(actions.getAdminOrder.failure.type)]);
      const [success, failure] = effects;

      if ((success?.payload.entity.id || failure?.payload.id) === id) {
        const status = success?.payload.entity.refund?.status;
        if (status === 'refunded' || status === 'failed') return;
        break;
      }
    }
  }
}

const handleResendAdminOrderEmailRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.resendAdminOrderEmail,
  request: AdminEventsApi.resendEmail,
  requestArgsBuilder: (action) => [action.payload.params.eventId, action.payload.id],
});

const handleSetPayOfflineAdminOrderRequest = featureSagasHandlersFactory.createMultipleFeatureRequestHandler({
  actions: actions.setPayOfflineAdminOrder,
  request: AdminEventsApi.payOffline,
  requestArgsBuilder: (action) => {
    const { id, params } = action.payload;
    const { eventId, ...other } = params;
    return [eventId, id, { body: { ...other } }];
  },
});

export default function* adminOrdersSagas() {
  yield takeEvery(actions.getAdminOrder.request.type, handleGetAdminOrderRequest);
  yield takeEvery(actions.getAdminOrders.request.type, handleGetAdminOrdersRequest);
  yield takeEvery(actions.getAdminOrdersStats.request.type, handleGetAdminOrdersStatsRequest);
  yield takeLeading(actions.fullRefundAdminOrder.request.type, handleFullRefundAdminOrderRequest);
  yield takeEvery(actions.fullRefundAdminOrder.success.type, handleFullRefundAdminOrderSuccess);
  yield takeEvery(actions.resendAdminOrderEmail.request.type, handleResendAdminOrderEmailRequest);
  yield takeEvery(actions.setPayOfflineAdminOrder.request.type, handleSetPayOfflineAdminOrderRequest);
}
