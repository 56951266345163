import {
  OfflineOrderCheckout,
  OrderAdmin,
  OrderAdminDetail,
  OrderFullRefundRequest,
  OrdersAggregatedData,
} from 'api/admin/models';
import { actionsFactories } from 'store/entities/utils';
import { EntityType } from 'store/entities/types';
import { actionsFactories as featureActionsFactories } from 'store/features/utils';
import * as keyWindowNames from './keyWindowNames';
import {
  GetAdminOrdersParamsType,
  GetAdminOrderParamsType,
  ResendAdminOrderEmailParamsType,
  SetPayOfflineAdminOrderParamsType,
  FullRefundAdminOrderParamsType,
} from './types';

export const getAdminOrder = actionsFactories.createGetOneActionsFactory<
  EntityType<OrderAdminDetail>,
  GetAdminOrderParamsType
>()('GET_ADMIN_ORDER_REQUEST', 'GET_ADMIN_ORDER_SUCCESS', 'GET_ADMIN_ORDER_FAILURE', 'GET_ADMIN_ORDER_RESET');

export const getAdminOrders = actionsFactories.createGetPaginatedManyActionsFactory<
  EntityType<OrderAdmin>,
  GetAdminOrdersParamsType
>()(
  'GET_ADMIN_ORDERS_REQUEST',
  'GET_ADMIN_ORDERS_SUCCESS',
  'GET_ADMIN_ORDERS_FAILURE',
  'GET_ADMIN_ORDERS_RESET',
  keyWindowNames.adminOrders,
);

export const getAdminOrdersStats = actionsFactories.createGetOneActionsFactory<EntityType<OrdersAggregatedData>>()(
  'GET_ADMIN_ORDERS_STATS_REQUEST',
  'GET_ADMIN_ORDERS_STATS_SUCCESS',
  'GET_ADMIN_ORDERS_STATS_FAILURE',
  'GET_ADMIN_ORDERS_STATS_RESET',
);

export const fullRefundAdminOrder = featureActionsFactories.createMultipleFeatureActionsFactory<
  FullRefundAdminOrderParamsType,
  EntityType<OrderFullRefundRequest>
>()(
  'REFUND_ADMIN_ORDER_REQUEST',
  'REFUND_ADMIN_ORDER_SUCCESS',
  'REFUND_ADMIN_ORDER_FAILURE',
  'REFUND_ADMIN_ORDER_RESET',
);

export const resendAdminOrderEmail =
  featureActionsFactories.createMultipleFeatureActionsFactory<ResendAdminOrderEmailParamsType>()(
    'RESEND_ADMIN_ORDER_EMAIL_REQUEST',
    'RESEND_ADMIN_ORDER_EMAIL_SUCCESS',
    'RESEND_ADMIN_ORDER_EMAIL_FAILURE',
    'RESEND_ADMIN_ORDER_EMAIL_RESET',
  );

export const setPayOfflineAdminOrder = featureActionsFactories.createMultipleFeatureActionsFactory<
  SetPayOfflineAdminOrderParamsType,
  EntityType<OfflineOrderCheckout>
>()(
  'SET_PAY_OFFLINE_ADMIN_ORDER_REQUEST',
  'SET_PAY_OFFLINE_ADMIN_ORDER_SUCCESS',
  'SET_PAY_OFFLINE_ADMIN_ORDER_FAILURE',
  'SET_PAY_OFFLINE_ADMIN_ORDER_RESET',
);
