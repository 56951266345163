import { all } from 'redux-saga/effects';

import addonsSagas from './entities/addons/sagas';
import adminAdditionalInstructionTranslationsSagas from './entities/adminAdditionalInstructionTranslations/sagas';
import adminAddonsSagas from './entities/adminAddons/sagas';
import adminAttendeesSagas from './entities/adminAttendees/sagas';
import adminDonationTranslationsSagas from './entities/adminDonationTranslations/sagas';
import adminDonationsSagas from './entities/adminDonations/sagas';
import adminEventAdditionalInstructionsSagas from './entities/adminEventAdditionalInstructions/sagas';
import adminEventDashboardSagas from './entities/adminEventDashboard/sagas';
import adminEventTotalSalesStatisticsSagas from './entities/adminEventTotalSalesStatistics/sagas';
import adminEventTotalTicketsStatisticsSagas from './entities/adminEventTotalTicketsStatistics/sagas';
import adminEventTotalDonationsStatisticsSagas from './entities/adminEventTotalDonationsStatistics/sagas';
import adminEventTicketsStatisticsSagas from './entities/adminEventTicketsStatistics/sagas';
import adminEventGenderStatisticsSagas from './entities/adminEventGenderStatistics/sagas';
import adminEventAgeStatisticsSagas from './entities/adminEventAgeStatistics/sagas';
import adminEventNeighborhoodStatisticsSagas from './entities/adminEventNeighborhoodStatistics/sagas';
import adminEventImageTranslationsSagas from './entities/adminEventImageTranslations/sagas';
import adminEventImagesSagas from './entities/adminEventImages/sagas';
import adminEventSponsorsSagas from './entities/adminEventSponsors/sagas';
import adminEventMetaTagsSagas from './entities/adminEventMetaTags/sagas';
import adminEventOrganizationMentionsSagas from './entities/adminEventOrganizationMentions/sagas';
import adminEventRegistrantsEmailsSagas from './entities/adminEventRegistrantsEmails/sagas';
import adminEventRemindersSagas from './entities/adminEventReminders/sagas';
import adminEventSettingsSagas from './entities/adminEventSettings/sagas';
import adminEventTranslationsSagas from './entities/adminEventTranslations/sagas';
import adminEventVideosSagas from './entities/adminEventVideos/sagas';
import adminEventsSagas from './entities/adminEvents/sagas';
import adminOrdersSagas from './entities/adminOrders/sagas';
import adminOrganizationDashboardSagas from './entities/adminOrganizationDashboard/sagas';
import adminOrganizationImageTranslationSagas from './entities/adminOrganizationImagesTranslation/sagas';
import adminOrganizationImagesSagas from './entities/adminOrganizationImages/sagas';
import adminOrganizationReportsSagas from './entities/adminOrganizationReports/sagas';
import adminOrganizationTranslationSagas from './entities/adminOrganizationTranslation/sagas';
import adminOrganizationMetatags from './entities/adminOrganizationMetatags/sagas';
import adminOrganizationTypesSagas from './entities/adminOrganizationTypes/sagas';
import adminOrganizationVideosSagas from './entities/adminOrganizationVideos/sagas';
import adminOrganizationsAutoCompleteSagas from './entities/adminOrganizationsAutoComplete/sagas';
import adminOrganizationsSagas from './entities/adminOrganizations/sagas';
import adminPlatformUsersSagas from './entities/adminPlatformUsers/sagas';
import adminQuestionsSagas from './entities/adminQuestions/sagas';
import adminTicketsSagas from './entities/adminTickets/sagas';
import adminUsersSagas from './entities/adminUsers/sagas';
import adminZoomEventsSagas from './entities/adminZoomEvents/sagas';
import marketsSagas from './entities/markets/sagas';
import calendarSettingsSagas from './entities/calendarSettings/sagas';
import countriesSagas from './entities/countries/sagas';
import defaultMetaTagsSagas from './entities/defaultMetaTags/sagas';
import donationsSagas from './entities/donations/sagas';
import eventCategoriesSagas from './entities/eventCategories/sagas';
import eventEmailSubscriptionSagas from './entities/eventEmailSubscription/sagas';
import eventTargetAgesSagas from './entities/eventTargetAges/sagas';
import eventsSagas from './entities/events/sagas';
import notCompletedOrdersSagas from './entities/notCompletedOrders/sagas';
import optionsSagas from './entities/options/sagas';
import ordersSagas from './entities/orders/sagas';
import organizationsSagas from './entities/organizations/sagas';
import orgTypesSagas from './entities/orgTypes/sagas';
import stripePaymentMethodsSagas from './entities/stripePaymentMethods/sagas';
import spreedlyPaymentMethodsSagas from './entities/spreedlyPaymentMethods/sagas';
import tagsSagas from './entities/tags/sagas';
import ticketsSagas from './entities/tickets/sagas';
import userMfaSagas from './entities/userMfa/sagas';
import userSagas from './entities/users/sagas';
import adminPromoCodesSagas from './entities/adminPromoCodes/sagas';
import adminOrganizationChannelsSagas from './entities/adminOrganizationChannels/sagas';
import channelsSagas from './entities/channels/sagas';

import AdminPermissionSagas from './features/adminPermission/sagas';
import analyticsSaga from './features/analytics/sagas';
import authSagas from './features/auth/sagas';
import creatingOrderSagas from './features/creatingOrder/sagas';
import detectMarketSaga from './features/detectMarket/sagas';
import eventDraftSagas from './features/eventDraft/sagas';
import syncSagas from './features/sync/sagas';
import zoomSagas from './features/zoom/sagas';

import notificationsSagas from './ui/notifications/sagas';

export default function* rootSaga() {
  yield all([
    addonsSagas(),
    adminAdditionalInstructionTranslationsSagas(),
    adminAddonsSagas(),
    adminAttendeesSagas(),
    adminDonationTranslationsSagas(),
    adminDonationsSagas(),
    adminEventAdditionalInstructionsSagas(),
    adminEventDashboardSagas(),
    adminEventTotalSalesStatisticsSagas(),
    adminEventTotalTicketsStatisticsSagas(),
    adminEventTotalDonationsStatisticsSagas(),
    adminEventTicketsStatisticsSagas(),
    adminEventGenderStatisticsSagas(),
    adminEventAgeStatisticsSagas(),
    adminEventNeighborhoodStatisticsSagas(),
    adminEventImageTranslationsSagas(),
    adminEventImagesSagas(),
    adminEventSponsorsSagas(),
    adminEventMetaTagsSagas(),
    adminEventOrganizationMentionsSagas(),
    adminEventRegistrantsEmailsSagas(),
    adminEventRemindersSagas(),
    adminEventSettingsSagas(),
    adminEventTranslationsSagas(),
    adminEventVideosSagas(),
    adminEventsSagas(),
    adminOrdersSagas(),
    adminOrganizationDashboardSagas(),
    adminOrganizationImageTranslationSagas(),
    adminOrganizationImagesSagas(),
    adminOrganizationReportsSagas(),
    adminOrganizationTranslationSagas(),
    adminOrganizationMetatags(),
    adminOrganizationTypesSagas(),
    adminOrganizationVideosSagas(),
    adminOrganizationsAutoCompleteSagas(),
    adminOrganizationsSagas(),
    adminPlatformUsersSagas(),
    adminQuestionsSagas(),
    adminTicketsSagas(),
    adminUsersSagas(),
    adminZoomEventsSagas(),
    adminPromoCodesSagas(),
    adminOrganizationChannelsSagas(),
    channelsSagas(),
    marketsSagas(),
    calendarSettingsSagas(),
    countriesSagas(),
    donationsSagas(),
    defaultMetaTagsSagas(),
    eventCategoriesSagas(),
    eventEmailSubscriptionSagas(),
    eventTargetAgesSagas(),
    eventsSagas(),
    notCompletedOrdersSagas(),
    optionsSagas(),
    ordersSagas(),
    organizationsSagas(),
    orgTypesSagas(),
    stripePaymentMethodsSagas(),
    spreedlyPaymentMethodsSagas(),
    tagsSagas(),
    ticketsSagas(),
    userSagas(),
    userMfaSagas(),
    AdminPermissionSagas(),
    analyticsSaga(),
    authSagas(),
    creatingOrderSagas(),
    detectMarketSaga(),
    eventDraftSagas(),
    syncSagas(),
    zoomSagas(),
    notificationsSagas(),
  ]);
}
