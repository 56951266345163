import * as locales from 'date-fns/locale';
import { format, utcToZonedTime } from 'date-fns-tz';
import { isEqual, isSameDay, isSameMonth, isSameYear } from 'date-fns';
import { capitalize } from '@mui/material/utils';
import { i18n } from 'i18n';
import { getPaymentData } from './transform';

const currencies = {
  cad: { name: 'CAD', sign: '$' },
  usd: { name: '', sign: '$' },
  eur: { name: '', sign: '€' },
};

const formatPaymentDataByTypes = {
  card: ({ brand, last4 }) => `${capitalize(brand)} •••• ${last4}`,
  credit_card: ({ card_type, last_four_digits }) => `${capitalize(card_type)} •••• ${last_four_digits}`,
};

export const formatCurrencyName = (currency) => currencies[currency]?.name || '';

export const formatCurrencySign = (currency) => currencies[currency]?.sign || '';

// todo: move to i18n formatting
export const formatPriceValue = (price, decimal) =>
  decimal && !Number.isInteger(price)
    ? price.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : price.toLocaleString('en');

// eslint-disable-next-line default-param-last
export const formatPrice = (price = null, currency, noZero, decimal) => {
  if (noZero && !price) return i18n?.t('common:general.free').toUpperCase() || String(0);
  const sign = formatCurrencySign(currency);
  const name = formatCurrencyName(currency);
  const priceValue = formatPriceValue(price, decimal);
  return `${sign}${priceValue} ${name}`.trimEnd();
};

// eslint-disable-next-line default-param-last
export const formatPriceDashboardCard = (price = 0, currency, noZero, decimal) => {
  if (noZero && price === 0) return i18n?.t('common:general.free').toUpperCase() || String(0);
  let name = formatCurrencyName(currency);
  if (!name) {
    name = formatCurrencySign(currency);
  } else {
    name = `${name} `;
  }
  const priceValue = formatPriceValue(price, decimal);
  return `${name}${priceValue}`.trimEnd();
};

export const formatDiscount = (type, value, currency) => {
  if (type === 'flat') return formatPrice(-value, currency, false, true);
  if (type === 'percent') return `${-value}%`;
};

// todo: move to i18n formatting
export const formatPriceRange = (minPrice, maxPrice, currency, noZero, decimal) => {
  if (minPrice === maxPrice) return formatPrice(minPrice, currency, noZero, decimal);
  if (noZero && minPrice === 0) {
    const divider = i18n?.t('common:general.to') || '-';
    const minPriceStr = formatPrice(minPrice, currency, noZero, decimal);
    const maxPriceStr = formatPrice(maxPrice, currency, noZero, decimal);
    return `${minPriceStr} ${divider} ${maxPriceStr}`;
  }
  const sign = formatCurrencySign(currency);
  const name = formatCurrencyName(currency);
  const minPriceValue = formatPriceValue(minPrice, decimal);
  const maxPriceValue = formatPriceValue(maxPrice, decimal);
  return `${sign}${minPriceValue} - ${sign}${maxPriceValue} ${name}`.trimEnd();
};

export const formatPriceStart = (minPrice, maxPrice, currency, noZero, decimal) => {
  const minPriceStr = formatPrice(minPrice, currency, noZero, decimal);
  if (minPrice === maxPrice) return minPriceStr;
  if (minPrice === 0 && maxPrice > 0) return `${i18n?.t('common:general.startingAt').toLowerCase()} ${minPriceStr}`;

  return `${i18n?.t('common:general.from').toLowerCase()} ${minPriceStr}`;
};

// todo: move to i18n formatting
export const formatDateFns = (date, pattern, options) =>
  format(date, pattern, {
    ...options,
    locale: locales[i18n.language],
  });

export const formatDate = (date, pattern, timeZone) =>
  formatDateFns(utcToZonedTime(date, timeZone), pattern, { timeZone });

export const formatDateTime = (date, timeZone) =>
  formatDateFns(utcToZonedTime(date, timeZone), 'MMM do • p', { timeZone }).toLowerCase();

export const formatDuration = (startAt, endAt, timeZone = 'America/Detroit', dayOfWeek = true) => {
  const currentDate = new Date();
  const currentUtcDate = utcToZonedTime(currentDate.toISOString(), timeZone);

  if (!startAt) {
    return '';
  }
  const startAtDate = utcToZonedTime(startAt, timeZone);
  const isCurrentStartAtYear = isSameYear(startAtDate, currentUtcDate);

  let startAtPattern = dayOfWeek ? 'iii, MMM d' : 'MMM, d';
  if (!isCurrentStartAtYear) startAtPattern = `${startAtPattern}, yyyy`;

  const startAtFormatted = formatDateFns(startAtDate, startAtPattern, { timeZone });

  if (!endAt) {
    return startAtFormatted;
  }

  const endAtDate = utcToZonedTime(endAt, timeZone);
  const isCurrentEndAtYear = isCurrentStartAtYear && isSameYear(startAtDate, endAtDate);

  if (isSameDay(startAtDate, endAtDate)) {
    return startAtFormatted;
  }

  let endAtPattern;

  if (!isSameMonth(startAtDate, endAtDate)) {
    endAtPattern = dayOfWeek ? `iii, MMM d` : 'MMM d';
  } else {
    endAtPattern = dayOfWeek ? `iii, d` : 'd';
  }

  if (!isCurrentEndAtYear) {
    endAtPattern = `${endAtPattern}, yyyy`;
  }

  const endAtFormatted = formatDateFns(endAtDate, endAtPattern, { timeZone });

  return `${startAtFormatted} - ${endAtFormatted}`;
};

export const formatTimeDuration = (dateLeft, dateRight, timeZone = 'America/Detroit', withTimeZone = true) => {
  const startAtDate = utcToZonedTime(dateLeft, timeZone);

  const startAtFormatted = formatDateFns(startAtDate, 'p');
  const timeZoneFormatted = formatDateFns(startAtDate, 'zzz', { timeZone });
  const startTimeDuration = withTimeZone ? `${startAtFormatted} | ${timeZoneFormatted}` : `${startAtFormatted}`;

  if (!dateRight) return startTimeDuration;

  const endAtDate = utcToZonedTime(dateRight, timeZone);

  if (isEqual(startAtDate, endAtDate)) return startTimeDuration;

  const endAtFormatted = formatDateFns(endAtDate, 'p');

  if (!withTimeZone) return `${startAtFormatted} - ${endAtFormatted}`;
  return `${startAtFormatted} - ${endAtFormatted} | ${timeZoneFormatted}`;
};

export const getTranslationName = (key, language) => `${key}_${language}`;

export const hasDefaultLanguageTranslation = (initialValues, defaultLanguage) =>
  Object.keys(initialValues).some((key) => {
    const language = key.substring(key.length, key.length - 2);
    return language === defaultLanguage && Boolean(initialValues[key]);
  });

export const formatPaymentMethod = (paymentMethod) => {
  const formatPaymentData = formatPaymentDataByTypes[paymentMethod.type];
  if (formatPaymentData) return formatPaymentData(getPaymentData(paymentMethod));
  return null;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 B';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const formatUserShort = (user) => {
  if (user?.first_name || user?.last_name) return [user.first_name, user.last_name].join(' ');
  if (user?.attendee_email) return user.attendee_email;
  return '';
};

export const numberWithCommas = (value) => {
  if (Number.isNaN(+value)) {
    return value;
  }
  const nf = new Intl.NumberFormat('en-US');
  return nf.format(value);
};
