import { OrderAdminDetail, OrderAdminDetailStatusEnum } from 'api/admin/models';
import { createReducer } from 'store/utils';
import { RootActions } from 'store/types';
import { EntityType } from 'store/entities/types';
import { stateFactories, actionsHandlers } from 'store/entities/utils';
import * as actions from '../actions';

const stateFactory = stateFactories.createInitialEntitiesStateFactory<EntityType<OrderAdminDetail>>();
const initialState = stateFactory.getInitialState();

type State = typeof initialState;
type Actions = RootActions<typeof actions>;

const adminOrderReducer = createReducer<State, Actions>(initialState, {
  [actions.getAdminOrder.request.type]: actionsHandlers.handleGetOneRequest,
  [actions.getAdminOrder.success.type]: actionsHandlers.handleGetOneSuccess,
  [actions.getAdminOrder.failure.type]: actionsHandlers.handleGetOneFailure,
  [actions.getAdminOrder.reset.type]: actionsHandlers.handleGetOneReset,
  [actions.fullRefundAdminOrder.success.type]: (draft: any, action) => {
    draft.byId[action.payload.result.id] = action.payload.result;
  },
  [actions.setPayOfflineAdminOrder.success.type]: (draft, action) => {
    const order = draft.byId[action.payload.id];
    if (order) {
      order.status = OrderAdminDetailStatusEnum.PaidOffline;
    }
  },
});

export default adminOrderReducer;
