import { createSelector } from 'reselect';
import { RootState } from 'store/rootReducer';
import * as AuthSelectors from 'store/features/auth/selectors';
import * as AddonsSelectors from 'store/entities/addons/selectors';
import * as DonationsSelectors from 'store/entities/donations/selectors';
import * as EventsSelectors from 'store/entities/events/selectors';
import * as StripePaymentMethodsSelectors from 'store/entities/stripePaymentMethods/selectors';
import * as TicketsSelectors from 'store/entities/tickets/selectors';
import { EntityType } from 'store/entities/types';
import { Event } from 'api/general/models';
import { QUESTION_SLUG } from 'components/types/question';
import { AddonOrderItemType, ItemStringType, OrderItemStringType, TicketOrderItemType } from './types';
import { SpreedlyPaymentMethodsSelectors } from '../../selectors';

const completedOrderStatuses = new Set(['paid', 'waiting_for_offline_payment', 'succeeded']);

const calcTotalValue =
  (itemType: OrderItemStringType, calc: (total: number, orderEntity: any) => number) =>
  (orderItems: any[], orderEntities: any) =>
    orderItems.reduce((total, { [itemType]: id }) => {
      const orderEntity = orderEntities[id];
      if (orderEntity) return calc(total, orderEntity);
      return total;
    }, 0);

const calcTotalPrice = (itemType: OrderItemStringType) =>
  calcTotalValue(itemType, (total, orderEntity) => total + orderEntity.price);

const calcTotalTaxes = (itemType: OrderItemStringType) =>
  calcTotalValue(itemType, (total, orderEntity) => total + (orderEntity.price * orderEntity.tax_rate) / 100);

const calcOrderItemsCounts = (orderItems: any[], itemType: OrderItemStringType) =>
  orderItems.reduce((counts, { [itemType]: id }) => {
    if (!counts[id]) counts[id] = 0;
    counts[id] += 1;
    return counts;
  }, {} as Record<number, number>);

const getEntities = (itemType: OrderItemStringType) => (orderItems: any[], orderEntities: any) =>
  Object.values(
    orderItems.reduce((entities, { [itemType]: id }) => {
      if (!entities[id]) entities[id] = orderEntities[id];
      return entities;
    }, {} as Record<number, any>),
  );

const getEmailFromAnswers = (item: any, entity: any) => {
  const answer = item.answers.find((answer: any) => {
    const question = entity.questions.find((question: any) => question.id === answer.question);
    return question && question.type === 'custom' && question.slug === QUESTION_SLUG.EMAIL && answer.answer_data;
  });

  if (answer) return answer.answer_data.email;
  return null;
};

const getNameDataFromAnswers = (item: any) => {
  const answer = item.answers.find((answer: any) =>
    Object.keys(answer.answer_data).every((k) => k === 'first_name' || k === 'last_name'),
  );
  return answer?.answer_data;
};

export const cartSlice = (state: RootState) => state.features.creatingOrder.cart;

export const createOrderSlice = (state: RootState) => state.features.creatingOrder.createOrder;

export const payOrderSlice = (state: RootState) => state.features.creatingOrder.payOrder;

export const spreedlyPayOrderSlice = (state: RootState) => state.features.creatingOrder.spreedlyPayOrder;

export const confirmCardPaymentSlice = (state: RootState) => state.features.creatingOrder.confirmCardPayment;

export const spreedlyCheckPurchaseStatusSlice = (state: RootState) =>
  state.features.creatingOrder.spreedlyCheckPurchaseStatus;

export const donationSlice = (state: RootState) => state.features.creatingOrder.donation;

export const paymentSlice = (state: RootState) => state.features.creatingOrder.payment;

export const promoCodeSlice = (state: RootState) => state.features.creatingOrder.promoCode;

export const checkAttendeeSlice = (state: RootState) => state.features.creatingOrder.checkAttendee;

export const registrationSlice = (state: RootState) => state.features.creatingOrder.registration;

export const presentStepSelector = (state: RootState) => registrationSlice(state).present;

export const eventItemSelector = (state: RootState) => cartSlice(state).event;

export const paymentSystem = (state: RootState) => cartSlice(state).paymentSystem;

export const gateWayType = (state: RootState) => cartSlice(state).gateWayType;

export const ticketsItemsSelector = (state: RootState) => cartSlice(state).tickets;

export const addonsItemsSelector = (state: RootState) => cartSlice(state).addons;

export const isRegisteringByAdmin = (state: RootState) => cartSlice(state).isRegisteringByAdmin;

export const getTotalTicketsCount = (state: RootState) => ticketsItemsSelector(state).length;

export const getTotalAddonsCount = (state: RootState) => addonsItemsSelector(state).length;

export const hasTicketsCount = (state: RootState) => getTotalTicketsCount(state) > 0;

export const hasAddonsCount = (state: RootState) => getTotalAddonsCount(state) > 0;

export const getConvertToHardUser = (state: RootState) => cartSlice(state).convertToHardUser;

export const getSendTo = (state: RootState) => cartSlice(state).sendTo;

export const getPromoCode = (state: RootState) => promoCodeSlice(state).code;

export const getDiscount = (state: RootState) => promoCodeSlice(state).result;

export const getOrderStatus = (state: RootState) => createOrderSlice(state).result?.status;

export const getSetupFutureUsage = (state: RootState) => paymentSlice(state).setupFutureUsage;

export const getSelectedStripePaymentMethodId = (state: RootState) => paymentSlice(state).selectedStripePaymentMethodId;

export const getSelectedSpreedlyPaymentMethodId = (state: RootState) =>
  paymentSlice(state).selectedSpreedlyPaymentMethodId;

export const getPaidOffline = (state: RootState) => paymentSlice(state).paidOffline;

export const getDonationOption = (state: RootState) => donationSlice(state).option;

export const getCustomDonationAmount = (state: RootState) => donationSlice(state).custom_donation_amount;

export const isRequirementDonationAlreadyMet = (state: RootState) => donationSlice(state).requirement_already_met;

export const isOrderCreated = (state: RootState) => Boolean(createOrderSlice(state).result);

export const isOrderCompleted = (state: RootState) => {
  const status = getOrderStatus(state);
  return completedOrderStatuses.has(status);
};

export const getOnBehalfOf = (state: RootState) => donationSlice(state).on_behalf_of;

export const getTicketEntities = (state: RootState) => {
  const eventItem = eventItemSelector(state);
  if (eventItem) return TicketsSelectors.eventTicketsEntities(state, { eventId: eventItem.event });
  return [];
};

export const getAddonEntities = (state: RootState) => {
  const eventItem = eventItemSelector(state);
  if (eventItem) return AddonsSelectors.eventAddonsEntities(state, { eventId: eventItem.event });
  return [];
};

export const getEventEntity = (state: RootState) => {
  const eventItem = eventItemSelector(state);
  if (eventItem) return EventsSelectors.eventById(state, eventItem.event);
  return null;
};

export const getDonationEntity = (state: RootState) => {
  const eventItem = eventItemSelector(state);
  if (eventItem) return DonationsSelectors.donationById(state, eventItem.event);
  return null;
};

export const getOrderEntities = (state: RootState, itemType: OrderItemStringType) => {
  if (itemType === 'ticket') return getTicketEntities(state);
  if (itemType === 'addon') return getAddonEntities(state);
};

export const getOrderItems = (state: RootState, itemType: OrderItemStringType) => {
  if (itemType === 'ticket') return ticketsItemsSelector(state);
  if (itemType === 'addon') return addonsItemsSelector(state);
};

export const getSelectedDonationOption = (state: RootState) => {
  const id = getDonationOption(state);
  if (id) {
    const donation = getDonationEntity(state);
    if (donation) return donation.options.find((option) => option.id === id);
  }

  return null;
};

export const getSelectedStripePaymentMethod = createSelector(
  getSelectedStripePaymentMethodId,
  StripePaymentMethodsSelectors.getStripePaymentMethods,
  StripePaymentMethodsSelectors.getTempStripePaymentMethod,
  (id, stripePaymentMethods, tempStripePaymentMethod) => {
    if (!id) return null;
    if (tempStripePaymentMethod?.id === id) return tempStripePaymentMethod;
    return stripePaymentMethods.find((stripePaymentMethod) => stripePaymentMethod.id === id);
  },
);

export const getSelectedSpreedlyPaymentMethod = createSelector(
  getSelectedSpreedlyPaymentMethodId,
  SpreedlyPaymentMethodsSelectors.getSpreedlyPaymentMethods,
  SpreedlyPaymentMethodsSelectors.getTempSpreedlyPaymentMethod,
  (id, spreedlyPaymentMethods, tempSpreedlyPaymentMethod) => {
    if (!id) return null;
    if (tempSpreedlyPaymentMethod?.id === id) return tempSpreedlyPaymentMethod;
    return spreedlyPaymentMethods.find((spreedlyPaymentMethod) => spreedlyPaymentMethod.id === id);
  },
);

export const getTotalTicketsPrice = createSelector(
  ticketsItemsSelector,
  TicketsSelectors.ticketsById,
  calcTotalPrice('ticket'),
);

export const getTotalAddonsPrice = createSelector(
  addonsItemsSelector,
  AddonsSelectors.addonsById,
  calcTotalPrice('addon'),
);

export const getTotalTicketsTaxes = createSelector(
  ticketsItemsSelector,
  TicketsSelectors.ticketsById,
  calcTotalTaxes('ticket'),
);

export const getTotalAddonsTaxes = createSelector(
  addonsItemsSelector,
  AddonsSelectors.addonsById,
  calcTotalTaxes('addon'),
);

export const getTotalDonations = createSelector(
  isRequirementDonationAlreadyMet,
  getSelectedDonationOption,
  getCustomDonationAmount,
  (isRequirementDonationAlreadyMet, donationOption, customDonation) => {
    let total = 0;
    if (donationOption) total = donationOption.price + total;
    if (customDonation > 0 && !isRequirementDonationAlreadyMet) total = customDonation + total;
    return total;
  },
);

export const getTotalCartPrice = createSelector(
  getTotalTicketsPrice,
  getTotalAddonsPrice,
  (totalTicketsPrice, totalAddonsPrice) => totalTicketsPrice + totalAddonsPrice,
);

export const getTotalTaxes = createSelector(
  getTotalTicketsTaxes,
  getTotalAddonsTaxes,
  (totalTicketsTaxes, totalAddonsTaxes) =>
    Math.round((totalTicketsTaxes + totalAddonsTaxes) * 100 + Number.EPSILON) / 100,
);

export const getTotalDiscount = createSelector(
  getTotalCartPrice,
  getTotalTaxes,
  getDiscount,
  (totalCartPrice, taxes, discount) => {
    if (discount?.discount_type === 'flat') return discount.discount_value;
    if (discount?.discount_type === 'percent')
      return Math.round(discount.discount_value * (totalCartPrice + taxes)) / 100;
    return 0;
  },
);

export const getTotalPrice = createSelector(
  getTotalCartPrice,
  getTotalTaxes,
  getTotalDiscount,
  getTotalDonations,
  (totalCartPrice, totalTaxes, totalDiscount, totalDonations) =>
    Math.max(0, totalCartPrice + totalTaxes - totalDiscount) + totalDonations,
);

export const getTicketItemsCounts = createSelector(ticketsItemsSelector, () => 'ticket' as const, calcOrderItemsCounts);

export const getAddonsItemsCounts = createSelector(addonsItemsSelector, () => 'addon' as const, calcOrderItemsCounts);

export const getOrderItemsCounts = createSelector(getOrderItems, (state, itemType) => itemType, calcOrderItemsCounts);

// CLEAR ANY LATER
export const getOrderEntity = createSelector(
  getOrderEntities,
  (state: RootState, itemType: OrderItemStringType, id: number) => id,
  (orderEntities: any, id) => orderEntities.find((orderEntity: any) => orderEntity.id === id),
);

export const getCartEntity = (state: RootState, itemType: ItemStringType, itemIndex?: number) => {
  if (itemType === 'event') return getEventEntity(state);

  const item = getOrderItems(state, itemType)[itemIndex];
  if (item) {
    if (itemType === 'ticket') return TicketsSelectors.ticketById(state, (item as TicketOrderItemType)[itemType]);
    if (itemType === 'addon') return AddonsSelectors.addonById(state, (item as AddonOrderItemType)[itemType]);
  }
};

export const getCartItem = (state: RootState, itemType: ItemStringType, itemIndex?: number) => {
  if (itemType === 'event') return eventItemSelector(state);
  return getOrderItems(state, itemType)[itemIndex];
};

export const isLastStep = (state: RootState) => registrationSlice(state).future.length === 0;

export const isFirstStep = (state: RootState) => registrationSlice(state).past.length === 0;

export const isEmptySteps = (state: RootState) => {
  const slice = registrationSlice(state);
  return !slice.present && slice.past.length === 0 && slice.future.length === 0;
};

export const currentItemType = (state: RootState) => {
  const step = presentStepSelector(state);
  if (step) return step.itemType;
};

export const currentCartItem = (state: RootState) => {
  const step = presentStepSelector(state);
  if (step) {
    const { itemType, itemIndex } = step;
    return getCartItem(state, itemType, itemIndex);
  }
};

export const currentCartEntity = (state: RootState) => {
  const step = presentStepSelector(state);
  if (step) {
    const { itemType, itemIndex } = step;
    return getCartEntity(state, itemType, itemIndex);
  }
};

export const currentEvent = (state: RootState) => getCartEntity(state, 'event') as EntityType<Event>;

export const currentItemsSize = (state: RootState) => {
  const step = presentStepSelector(state);
  if (step) {
    const { itemType } = step;
    if (itemType === 'event') return Number(eventItemSelector(state));
    return getOrderItems(state, itemType).length;
  }
};

export const currentQuestions = (state: RootState) => {
  const step = presentStepSelector(state);
  if (step) {
    const { itemType, itemIndex } = step;
    const entity: any = getCartEntity(state, itemType, itemIndex);
    if (entity) return entity.questions;
  }
};

export const currentAnswers = (state: RootState) => {
  const item = currentCartItem(state);
  if (item) return item.answers;
};

export const currentProgress = (state: RootState) => {
  const { past, future } = registrationSlice(state);
  return past.length / (future.length + past.length + 1);
};

export const currentAttendee = (state: RootState) => {
  const item = currentCartItem(state);
  const entity: any = currentCartEntity(state);
  if (item && entity) {
    const question = entity.questions.find((question: any) => question.slug === 'full-name');
    if (question) {
      const answer = item.answers.find((answer) => answer.question === question.id);
      if (answer) return answer.answer_data;
    }
  }
};

export const currentTicketEntities = createSelector(
  ticketsItemsSelector,
  TicketsSelectors.ticketsById,
  getEntities('ticket'),
);

export const currentAddonEntities = createSelector(
  addonsItemsSelector,
  AddonsSelectors.addonsById,
  getEntities('addon'),
);

export const forBuyer = (state: RootState) => (currentCartItem(state) as TicketOrderItemType)?.for_buyer;

export const currentUser = (state: RootState) => {
  if (isRegisteringByAdmin(state)) return null;
  return AuthSelectors.currentUser(state);
};

export const isBuyerQuestion = createSelector(
  forBuyer,
  currentItemType,
  isRegisteringByAdmin,
  (forBuyer, itemType, isRegisteringByAdmin) => !isRegisteringByAdmin && (forBuyer || itemType === 'event'),
);

export const hasForBuyerAnswer = createSelector(ticketsItemsSelector, presentStepSelector, (tickets) =>
  tickets.some(
    (ticket) => ticket.for_buyer,
    // TODO: ???
    // || (step.itemType === 'ticket' && step.itemIndex === index && ticket.for_buyer === false),
  ),
);

export const getDefaultCurrency = createSelector(currentEvent, (event: EntityType<Event>) => event?.default_currency);

export const getBuyerEmailFromEventAnswers = createSelector(
  eventItemSelector,
  getEventEntity,
  (eventItem, eventEntity) => {
    if (eventItem && eventEntity) {
      const user = getEmailFromAnswers(eventItem, eventEntity);
      if (user) return user;
    }
    return null;
  },
);

export const getBuyerEmailFromAnswers = createSelector(
  eventItemSelector,
  getEventEntity,
  ticketsItemsSelector,
  getTicketEntities,
  (eventItem, eventEntity, ticketItems, ticketEntities) => {
    if (eventItem && eventEntity) {
      const email = getEmailFromAnswers(eventItem, eventEntity);
      if (email) return email;
    }

    const ticketItem = ticketItems.find((ticketItem) => ticketItem.for_buyer);
    if (ticketItem) {
      const ticketEntity = ticketEntities.find((ticketEntity) => ticketEntity.id === ticketItem.ticket);
      if (ticketEntity) {
        const email = getEmailFromAnswers(ticketItem, ticketEntity);
        if (email) return email;
      }
    }
    return null;
  },
);

const getAnswerDataEventAnswers = (item: any, entity: any, slug: string) => {
  const answer = item.answers.find((answer: any) => {
    const question = entity.questions.find((question: any) => question.id === answer.question);
    return question && question.slug === slug && answer.answer_data;
  });
  if (answer) return answer.answer_data;
  return null;
};

export const getAnswerDataFromEventAnswers = createSelector(
  eventItemSelector,
  getEventEntity,
  (state: RootState, slug: string) => slug,
  (eventItem, eventEntity, slug) => {
    if (eventItem && eventEntity) {
      const answerData = getAnswerDataEventAnswers(eventItem, eventEntity, slug);
      if (answerData) return answerData;
    }
  },
);

export const getBuyerNameDataFromEventAnswers = createSelector(eventItemSelector, (eventItem) => {
  if (eventItem) {
    const nameDate = getNameDataFromAnswers(eventItem);
    return nameDate;
  }
  return null;
});
