import { addMinutes, isValid, parseISO } from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import domPurify from './domPurify';

const typeByAddressKey = {
  country: 'country',
  postal_code: 'postal_code',
  administrative_area_level_1: 'state',
  locality: 'city',
  route: 'address1',
};

const colorByDisabledStatus = {
  sold_out: 'red',
  on_hold: 'orange',
  closed: 'default',
  canceled: 'red',
  past: 'default',
};

const getDefaultPlaceComponentValue = ({ short_name }) => short_name;
const addressKeyByPlaceComponentValue = {
  country: getDefaultPlaceComponentValue,
  postal_code: getDefaultPlaceComponentValue,
  state: getDefaultPlaceComponentValue,
  city: getDefaultPlaceComponentValue,
  address1: ({ short_name: route }, components) => {
    const values = [route];

    components.some(({ short_name, types }) => {
      if (!types.includes('street_number')) return false;
      values.unshift(short_name);
      return true;
    });

    return values.join(' ');
  },
};

export const placeToAddress = (place) => {
  if (!place) return null;
  const { name: address_name, address_components, geometry } = place;
  const { location } = geometry;
  const address = {
    address_name,
    lat: location.lat().toFixed(8),
    lng: location.lng().toFixed(8),
  };

  return address_components.reduce((address, component, index, components) => {
    const type = component.types.find((type) => typeByAddressKey[type]);
    if (type) {
      const addressKey = typeByAddressKey[type];
      address[addressKey] = addressKeyByPlaceComponentValue[addressKey](component, components);
    }

    return address;
  }, address);
};

export const addressToFormAddress = (address, countries) => {
  const { country, state, address_additional_info = '', address1 = '', address2 = '', ...prepared } = address;
  prepared.address_additional_info = address_additional_info;
  prepared.address1 = address1;
  prepared.address2 = address2;
  prepared.country = '';
  prepared.state = '';

  const index = countries.findIndex(({ code }) => code === address.country);
  if (index !== -1) {
    prepared.country = country;
    if (Array.isArray(countries[index].states) && countries[index].states.some(({ code }) => code === state)) {
      prepared.state = state;
    }
  }

  return prepared;
};

export const outputDateToISOString = (date, timeZone, part = 'startOfDay') => {
  if (!(date && isValid(date))) return null;

  const outputDate = new Date(date);
  if (part === 'startOfDay') {
    outputDate.setHours(0, 0, 0, 0);
  } else outputDate.setHours(23, 59, 59, 0);

  return zonedTimeToUtc(outputDate, timeZone).toISOString();
};

export const inputISOStringToDate = (isoString, timeZone) => {
  if (!(isoString && isValid(parseISO(isoString)))) return null;
  return utcToZonedTime(isoString, timeZone);
};

export const toOrdering = (order, orderBy) => {
  if (!(order && orderBy)) return null;
  if (order === 'asc') return orderBy;
  if (orderBy.includes(',')) return `-${orderBy.split(',').join(',-')}`;
  return `-${orderBy}`;
};

export const parseOrdering = (ordering) => {
  let order = null;
  let orderBy = null;

  if (ordering) {
    if (ordering.startsWith('-')) {
      order = 'desc';
      orderBy = ordering.slice(1);
    } else {
      order = 'asc';
      orderBy = ordering;
    }
  }

  return {
    order,
    orderBy,
  };
};

export const getPaymentData = (paymentMethod) => {
  if (paymentMethod.payment_method_data) return paymentMethod.payment_method_data;
  if (paymentMethod.type === 'credit_card') return paymentMethod;
  return paymentMethod[paymentMethod.type];
};

export const getYoutubeId = (url) => {
  const regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
  const matches = url.match(regExp);
  return matches ? matches[1] : null;
};

export const getVimeoId = (url) => {
  const regExp =
    // eslint-disable-next-line max-len
    /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
  const matches = url.match(regExp);
  return matches ? matches[4] : null;
};

const parseISO8601Duration = (iso8601Duration) => {
  // eslint-disable-next-line max-len
  const iso8601DurationRegex =
    /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;
  const matches = iso8601Duration.match(iso8601DurationRegex) || [];

  return {
    sign: matches[1] ? '+' : '-',
    years: matches[2] || 0,
    months: matches[3] || 0,
    weeks: matches[4] || 0,
    days: matches[5] || 0,
    hours: matches[6] || 0,
    minutes: matches[7] || 0,
    seconds: matches[8] || 0,
  };
};

export const getHoursISODuration = (iso8601Duration) => {
  const parse = parseISO8601Duration(iso8601Duration);
  return Number(parse.hours) + Number(parse.days) * 24;
};

export const isOneDaySession = (startAt, endAt) => endAt.getTime() - startAt.getTime() < 24 * 60 * 60 * 1000;

export const sessionsHasSession = (sessions, session) =>
  sessions.some((s) => s.start_at === session.start_at && s.end_at === session.end_at);

export const getSessionsGroups = (sessions, timeZone) => {
  const map = sessions.reduce((map, session) => {
    const startAt = utcToZonedTime(session.start_at, timeZone);
    const endAt = utcToZonedTime(session.end_at, timeZone);

    if (isOneDaySession(startAt, endAt)) {
      const key = format(startAt, 'yyyy-MM-dd', { timeZone });
      if (!map.has(key)) map.set(key, []);
      map.get(key).push(session);
    }

    return map;
  }, new Map());

  return Array.from(map.values());
};

export const getPercent = (number, total) => (number / total) * 100;

export const getTextFromHtml = (text) => domPurify?.sanitize(text, { ALLOWED_TAGS: [] }).replace(/&nbsp;/g, ' ');

export const getDisabledEventStatus = (ticketStatus, eventStatus) => {
  const statusColor = colorByDisabledStatus[eventStatus] || colorByDisabledStatus[ticketStatus];

  return (
    statusColor && {
      color: statusColor,
      title: Object.keys(colorByDisabledStatus).includes(eventStatus) ? eventStatus : ticketStatus,
    }
  );
};

export const MARKETS_SLUGS_MAP = {
  cincinnati: ['cincinnati', 'usa', 'north-america', 'international'],
  'northern-virginia': ['northern-virginia', 'usa', 'north-america', 'international'],
  detroit: ['detroit', 'usa', 'north-america', 'international'],
  montreal: ['montreal', 'canada', 'north-america', 'international'],
  'silicon-valley': ['silicon-valley', 'usa', 'north-america', 'international'],
  usa: ['usa', 'north-america', 'international'],
  canada: ['canada', 'north-america', 'international'],
  'north-america': ['north-america', 'international'],
  international: ['international'],
  'test-cincinnati': ['test-cincinnati', 'usa', 'north-america', 'international'],
};

export const isObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]';

export const replaceValue = (values, key, value) => {
  if (value === undefined) return values;
  return {
    ...values,
    [key]: value,
  };
};

export const getDiff = (prevValues, nextValues) =>
  Object.keys(nextValues).reduce((values, key) => {
    const prevValue = prevValues === undefined || prevValues === null ? prevValues : prevValues[key];
    const nextValue = nextValues[key];

    let value;
    if (isObject(nextValue)) {
      value = getDiff(prevValue, nextValue);
    } else if (prevValue !== nextValue) {
      value = nextValue;
    } else if (Array.isArray(nextValue) && prevValue) {
      const difference = nextValue.filter((x, index) =>
        isObject(x) || Array.isArray(x) ? getDiff(prevValue[index], x) : prevValue[index] !== x,
      );
      value = difference.length === 0 ? undefined : difference;
    }
    return replaceValue(values, key, value);
  }, undefined);

export const getDateOffset = (date) => addMinutes(new Date(date), new Date().getTimezoneOffset());
