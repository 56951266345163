import { combineReducers } from 'redux';
import adminOrderReducer from './adminOrderReducer';
import adminOrdersReducer from './adminOrdersReducer';
import adminOrdersStatsReducer from './adminOrdersStatsReducer';
import fullRefundAdminOrderReducer from './fullRefundAdminOrderReducer';
import resendAdminOrderEmailReducer from './resendAdminOrderEmailReducer';
import setPayOfflineAdminOrderReducer from './setPayOfflineAdminOrderReducer';

export default combineReducers({
  details: adminOrderReducer,
  lists: adminOrdersReducer,
  adminOrdersStats: adminOrdersStatsReducer,
  fullRefundAdminOrder: fullRefundAdminOrderReducer,
  resendAdminOrderEmail: resendAdminOrderEmailReducer,
  payOffline: setPayOfflineAdminOrderReducer,
});
