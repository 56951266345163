import { combineReducers } from 'redux';

import hydrateReducer from './hydrateReducer';

import addonsReducer from './entities/addons/reducer';
import adminAdditionalInstructionTranslationsReducer from './entities/adminAdditionalInstructionTranslations/reducer';
import adminAddonsReducer from './entities/adminAddons/reducer';
import adminAttendeesReducer from './entities/adminAttendees/reducer';
import adminDonationTranslationsReducer from './entities/adminDonationTranslations/reducer';
import adminDonationsReducer from './entities/adminDonations/reducer';
import adminEventAdditionalInstructionsReducer from './entities/adminEventAdditionalInstructions/reducer';
import adminEventDashboardReducer from './entities/adminEventDashboard/reducer';
import adminEventTotalSalesStatisticsReducer from './entities/adminEventTotalSalesStatistics/reducer';
import adminEventTotalTicketsStatisticsReducer from './entities/adminEventTotalTicketsStatistics/reducer';
import adminEventTicketsStatisticsReducer from './entities/adminEventTicketsStatistics/reducer';
import adminEventImageTranslationsReducer from './entities/adminEventImageTranslations/reducer';
import adminEventImagesReducer from './entities/adminEventImages/reducer';
import adminEventSponsorsReducer from './entities/adminEventSponsors/reducer';
import adminEventMetaTagsReducer from './entities/adminEventMetaTags/reducer';
import adminEventOrganizationMentionsReducer from './entities/adminEventOrganizationMentions/reducer';
import adminEventRegistrantsEmailsReducer from './entities/adminEventRegistrantsEmails/reducer';
import adminEventRemindersReducer from './entities/adminEventReminders/reducer';
import adminEventSettingsReducer from './entities/adminEventSettings/reducer';
import adminEventTranslationsReducer from './entities/adminEventTranslations/reducer';
import adminEventVideosReducer from './entities/adminEventVideos/reducer';
import adminEventsReducer from './entities/adminEvents/reducer';
import adminOrdersReducer from './entities/adminOrders/reducer';
import adminOrganizationDashboardReducer from './entities/adminOrganizationDashboard/reducer';
import adminOrganizationImageTranslationReducer from './entities/adminOrganizationImagesTranslation/reducer';
import adminOrganizationImagesReducer from './entities/adminOrganizationImages/reducer';
import adminOrganizationsMetaTagsReducer from './entities/adminOrganizationMetatags/reducer';
import adminOrganizationReportsReducer from './entities/adminOrganizationReports/reducer';
import adminOrganizationTranslationReducer from './entities/adminOrganizationTranslation/reducer';
import adminOrganizationTypesReducer from './entities/adminOrganizationTypes/reducer';
import adminOrganizationVideosReducer from './entities/adminOrganizationVideos/reducer';
import adminOrganizationsAutoCompleteReducer from './entities/adminOrganizationsAutoComplete/reducer';
import adminOrganizationsReducer from './entities/adminOrganizations/reducer';
import adminPlatformUsersReducer from './entities/adminPlatformUsers/reducer';
import adminQuestionsReducer from './entities/adminQuestions/reducer';
import adminTicketsReducer from './entities/adminTickets/reducer';
import adminUsersReducer from './entities/adminUsers/reducer';
import adminZoomEventsReducer from './entities/adminZoomEvents/reducer';
import marketsReducer from './entities/markets/reducer';
import calendarSettingsReducer from './entities/calendarSettings/reducer';
import countriesReducer from './entities/countries/reducer';
import defaultMetaTagsReducer from './entities/defaultMetaTags/reducer';
import donationsReducer from './entities/donations/reducer';
import eventCategoriesReducer from './entities/eventCategories/reducer';
import eventEmailSubscriptionReducer from './entities/eventEmailSubscription/reducer';
import eventTargetAgesReducer from './entities/eventTargetAges/reducer';
import eventsReducer from './entities/events/reducer';
import notCompletedOrdersReducer from './entities/notCompletedOrders/reducer';
import optionsReducer from './entities/options/reducer';
import ordersReducer from './entities/orders/reducer';
import organizationsReducer from './entities/organizations/reducer';
import stripeReducer from './entities/stripePaymentMethods/reducer';
import spreedlyReducer from './entities/spreedlyPaymentMethods/reducer';
import tagsReducer from './entities/tags/reducer';
import ticketsReducer from './entities/tickets/reducer';
import userMfaReducer from './entities/userMfa/reducer';
import usersReducer from './entities/users/reducer';
import adminPromoCodesReducer from './entities/adminPromoCodes/reducer';
import adminOrganizationChannelsReducer from './entities/adminOrganizationChannels/reducer';

import adminPermissionReducer from './features/adminPermission/reducer';
import analyticsReducer from './features/analytics/reducer';
import authReducer from './features/auth/reducer';
import creatingOrderReducer from './features/creatingOrder/reducer';
import eventDraftReducer from './features/eventDraft/reducer';
import detectMarketReducer from './features/detectMarket/reducer';
import zoomReducer from './features/zoom/reducer';

import notificationsReducer from './ui/notifications/reducer';
import adminEventTotalDonationsStatisticsReducer from './entities/adminEventTotalDonationsStatistics/reducer';
import adminEventGenderStatisticsReducer from './entities/adminEventGenderStatistics/reducer';
import adminEventAgeStatisticsReducer from './entities/adminEventAgeStatistics/reducer';
import adminEventNeighborhoodStatisticsReducer from './entities/adminEventNeighborhoodStatistics/reducer';
import getOrgTypesReducer from './entities/orgTypes/reducer';
import channelsReducer from './entities/channels/reducer';

const combinedReducer = combineReducers({
  entities: combineReducers({
    addons: addonsReducer,
    adminAdditionalInstructionTranslations: adminAdditionalInstructionTranslationsReducer,
    adminAddons: adminAddonsReducer,
    adminAttendees: adminAttendeesReducer,
    adminDonationTranslations: adminDonationTranslationsReducer,
    adminDonations: adminDonationsReducer,
    adminEventAdditionalInstructions: adminEventAdditionalInstructionsReducer,
    adminEventDashboard: adminEventDashboardReducer,
    adminEventTotalSalesStatistics: adminEventTotalSalesStatisticsReducer,
    adminEventTotalTicketsStatistics: adminEventTotalTicketsStatisticsReducer,
    adminEventTotalDonationsStatistics: adminEventTotalDonationsStatisticsReducer,
    adminEventTicketsStatistics: adminEventTicketsStatisticsReducer,
    adminEventGenderStatistics: adminEventGenderStatisticsReducer,
    adminEventAgeStatistics: adminEventAgeStatisticsReducer,
    adminEventNeighborhoodStatistics: adminEventNeighborhoodStatisticsReducer,
    adminEventImageTranslations: adminEventImageTranslationsReducer,
    adminEventImages: adminEventImagesReducer,
    adminEventSponsors: adminEventSponsorsReducer,
    adminEventMetaTags: adminEventMetaTagsReducer,
    adminEventOrganizationMentions: adminEventOrganizationMentionsReducer,
    adminEventRegistrantsEmails: adminEventRegistrantsEmailsReducer,
    adminEventReminders: adminEventRemindersReducer,
    adminEventSettings: adminEventSettingsReducer,
    adminEventTranslations: adminEventTranslationsReducer,
    adminEventVideos: adminEventVideosReducer,
    adminEvents: adminEventsReducer,
    adminOrders: adminOrdersReducer,
    adminOrganizationDashboard: adminOrganizationDashboardReducer,
    adminOrganizationImageTranslation: adminOrganizationImageTranslationReducer,
    adminOrganizationImages: adminOrganizationImagesReducer,
    adminOrganizationMetaTags: adminOrganizationsMetaTagsReducer,
    adminOrganizationReports: adminOrganizationReportsReducer,
    adminOrganizationTranslation: adminOrganizationTranslationReducer,
    adminOrganizationTypes: adminOrganizationTypesReducer,
    adminOrganizationVideos: adminOrganizationVideosReducer,
    adminOrganizations: adminOrganizationsReducer,
    adminOrganizationsAutoComplete: adminOrganizationsAutoCompleteReducer,
    adminPlatformUsers: adminPlatformUsersReducer,
    adminQuestions: adminQuestionsReducer,
    adminTickets: adminTicketsReducer,
    adminUsers: adminUsersReducer,
    adminZoomEvents: adminZoomEventsReducer,
    adminPromoCodes: adminPromoCodesReducer,
    adminOrganizationChannels: adminOrganizationChannelsReducer,
    channels: channelsReducer,
    markets: marketsReducer,
    calendarSettings: calendarSettingsReducer,
    countries: countriesReducer,
    donations: donationsReducer,
    defaultMetaTags: defaultMetaTagsReducer,
    eventCategories: eventCategoriesReducer,
    eventEmailSubscription: eventEmailSubscriptionReducer,
    eventTargetAges: eventTargetAgesReducer,
    events: eventsReducer,
    notCompletedOrders: notCompletedOrdersReducer,
    options: optionsReducer,
    orders: ordersReducer,
    organizations: organizationsReducer,
    orgTypes: getOrgTypesReducer,
    stripe: stripeReducer,
    spreedly: spreedlyReducer,
    tags: tagsReducer,
    tickets: ticketsReducer,
    users: usersReducer,
    mfa: userMfaReducer,
  }),
  features: combineReducers({
    adminPermission: adminPermissionReducer,
    analytics: analyticsReducer,
    auth: authReducer,
    creatingOrder: creatingOrderReducer,
    eventDraft: eventDraftReducer,
    detectMarket: detectMarketReducer,
    zoom: zoomReducer,
  }),
  ui: combineReducers({
    notifications: notificationsReducer,
  }),
});

export type RootState = ReturnType<typeof combinedReducer>;

export default function rootReducer(state: any, action: any) {
  return combinedReducer(hydrateReducer(state, action), action);
}
